<template>
  <client-only style="width:100%;height:100%">
    <div class="AutoTourControl" v-if="state=='mini'">
      <template v-if="autoTourPlayer.totalDuration>30000">
      </template>
      <div style="display: flex; justify-content: flex-end">
        <span class="text-shadow" style="flex: none; padding: 0.5em 1em;font-size:12px;opacity:0.7;cursor:pointer;" @click="setState('normal')">{{ moment(autoTourPlayer.totalDuration-autoTourPlayer.currentTime).format('mm:ss')}}</span>
      </div>
      <div class="lineRange"><div class="line" :style="{width:(autoTourPlayer.currentTime/autoTourPlayer.totalDuration)*100+'%'}"></div></div>
    </div>
    <div class="AutoTourControl" v-else-if="state=='normal'">
      <div class="" style="display: flex; align-items: center; padding: 0.5em; font-size: 12px; position: relative">
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-if="autoTourPlayer.state!='play'" @click="autoTourPlayer.play"><i class="fas fa-play"></i></a>
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-else @click="autoTourPlayer.pause"><i class="fas fa-pause"></i></a>
        <span class="text-shadow" style="flex:none">{{ moment(autoTourPlayer.currentTime).format('mm:ss')}}</span>
        <input type="range" class="" style="flex: auto; margin: 0 5px; cursor: pointer;" :style="{background:`linear-gradient(to right, #059CFA, #059CFA ${(autoTourPlayer.currentTime/autoTourPlayer.totalDuration)*100}%, #ebeff4 ${(autoTourPlayer.currentTime/autoTourPlayer.totalDuration)*100}%, #ebeff4)`}" min="0" :max="autoTourPlayer.totalDuration" v-model="autoTourPlayer.currentTime" />
        <span class="text-shadow" style="flex:none">{{moment(autoTourPlayer.totalDuration).format('mm:ss')}}</span>
        <div v-if="endBtn" class="text-shadow" style="flex: none; padding: 0 1em; cursor: pointer;" @click="$emit('toEnd')">跳过</div>
        <!--<a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" @click="stop"><i class="fas fa-stop"></i></a>-->
        <!--<div style="background-color:wheat">{{nowTime}}/{{nowDate}}/{{state}}</div>-->
      </div>
    </div>
    <div class="AutoTourControl" v-else>
      <div class="btnbox">
        <div v-if="autoTourPlayer.continueBtn" class="text-shadow" style="cursor: pointer;" @click="autoTourPlayer.play();autoTourPlayer.continueBtn=null">继续</div>
        <div v-if="endBtn && autoTourPlayer.continueBtn==null" class="text-shadow" style="cursor: pointer;" @click="$emit('toEnd');endBtn=null">跳过</div>
      </div>
      <div class="" style="display:flex;padding:0.5em;font-size:12px;position:relative">
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-if="autoTourPlayer.state!='play'" @click="autoTourPlayer.play"><i class="fas fa-play"></i></a>
        <a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" v-else @click="autoTourPlayer.pause"><i class="fas fa-pause"></i></a>
        <span class="text-shadow" style="flex:none">{{ moment(autoTourPlayer.currentTime).format('mm:ss')}}</span>
        <input type="range" class="" min="0" :max="autoTourPlayer.totalDuration" v-model="autoTourPlayer.currentTime" style="flex: auto; margin: 0 5px;" />
        <span class="text-shadow" style="flex:none">{{moment(autoTourPlayer.totalDuration).format('mm:ss')}}</span>
        <!--<a href="javascript:" class="text-white" style="flex:none;padding:0 0.5em;" @click="stop"><i class="fas fa-stop"></i></a>-->
        <!--<div style="background-color:wheat">{{nowTime}}/{{nowDate}}/{{state}}</div>-->
      </div>
    </div>
  </client-only>
</template>
<script>
  import moment from 'moment'
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        moment: moment,
        state: 'mini',
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      endBtn() {
        return this.config.endBtn
      },
      autoTourPlayer() {
        return this.publicData.autoTourPlayer || {}
      },
    },
    watch: {
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          setState: this.setState
        },
        events: {
        }
      })
    },
    destroyed() {
    },
    methods: {
      setState(state) {
        this.state = state
      },
      setTime(t) {
        var time = Number(Number(t).toFixed(0))
        this.autoTourPlayer.currentTime = time
      },
    },
  }
</script>
<style scoped>
  .lineRange {
    height: 2px;
    width: 100%;
    background-color: #666;
    position: relative
  }

    .lineRange > .line {
      background-color: #fff;
      height: 100%;
    }

  input[type="range"] {
    /*-webkit-box-shadow: 0 1px 0 0px #424242, 0 1px 0 #060607 inset, 0px 2px 10px 0px black inset, 1px 0px 2px rgba(0, 0, 0, 0.4) inset, 0 0px 1px rgba(0, 0, 0, 0.6) inset;*/
    -webkit-appearance: none; /*去除默认样式*/
    margin-top: 42px;
    background-color: #ebeff4;
    /*border-radius: 15px;*/
    width: 80% !important;
    -webkit-appearance: none;
    height: 2px;
    padding: 0;
    border: none;
    /*input的长度为80%，margin-left的长度为10%*/
  }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none; /*去除默认样式*/
      cursor: default;
      top: 0;
      height: 6px;
      width: 6px;
      transform: translateY(0px);
      /*background: none repeat scroll 0 0 #5891f5;*/
      background: #fff;
      border-radius: 15px;
      /*border: 5px solid #006eb3;*/
      /*-webkit-box-shadow: 0 -1px 1px #fc7701 inset;*/
      animation: all 0.5s;
    }

      /*input[type="range"]::-webkit-slider-thumb:active,*/
      input[type="range"]:active::-webkit-slider-thumb,
      input[type="range"]::-webkit-slider-thumb:hover {
        height: 12px;
        width: 12px;
      }
</style>
